import Partners1 from '@/assets/Partners1.png';
import Partners10 from '@/assets/Partners10.png';
import Partners2 from '@/assets/Partners2.png';
import Partners3 from '@/assets/Partners3.png';
import Partners4 from '@/assets/Partners4.png';
import Partners5 from '@/assets/Partners5.png';
import Partners6 from '@/assets/Partners6.png';
import Partners7 from '@/assets/Partners7.png';
import Partners8 from '@/assets/Partners8.png';
import Partners9 from '@/assets/Partners9.png';
import footerBg from '@/assets/footer-bg.png';
import { useInViewAnimated } from '@/hooks/useInViewAnimated';
import { animated } from '@react-spring/web';

import Marquee from 'react-fast-marquee';

const g1 = [
  [Partners1, 'https://solana.com/zh'],
  [Partners2, 'https://arweave.org/'],
  [Partners3, 'https://ai.meta.com/llama/'],
  [Partners4, 'https://claude.ai/'],
  [Partners5, 'https://io.net/'],
];
const g2 = [
  [Partners6, 'https://iotex.io/'],
  [Partners7, 'https://rendernetwork.com/'],
  [Partners8, 'https://clore.ai/'],
  [Partners9, 'https://akash.network/'],
  [Partners10, 'https://stablediffusionweb.com/'],
];


export const Partners: FC = () => {
  const [bgEl1Ref, bgEl1Spring] = useInViewAnimated(
    () => {
      return { to: [{ x: '-100%' }, { x: '0%', opacity: 1 }] };
    },
    { rootMargin: '-20% 0px -20% 0px' },
  );
  const [bgEl2Ref, bgEl2Spring] = useInViewAnimated(
    () => {
      return {
        to: [
          { x: '100%', y: '100%' },
          { x: '0%', y: '0%', opacity: 1 },
        ],
      };
    },
    { rootMargin: '-20% 0px -20% 0px' },
  );

  return (
    <div className="relative">
      <div className="relative content">
        <h1 className="text-5xl text-center sm:text-[1.75rem] sm:leading-[1.3]">
          Partners
        </h1>
        <div className="mt-[7.625rem] sm:mt-20">
          <Marquee autoFill pauseOnHover>
            <div className="flex items-center px-10 gap-x-20">
                {g1.map((item) => (
                    <a key={item[0]} href={item[1]} target='_blank' rel="noreferrer">
                        <img src={item[0]} className="sm:max-h-5" alt="image"/>
                    </a>
                ))}
            </div>
          </Marquee>
          <Marquee direction="right" autoFill pauseOnHover>
            <div className="flex items-center px-10 mt-20 gap-x-20 sm:mt-10">
              {g2.map((item) => (
                <a key={item[0]} href={item[1]} target='_blank' rel="noreferrer">
                  <img src={item[0]} className="sm:max-h-5" alt="image" />
                </a>
              ))}
            </div>
          </Marquee>
        </div>
      </div>

      <div className="bg-element w-[765px] sm:w-[500px] top-[4.375rem] left-[50%] -translate-x-[50%]">
        <img className="block w-full" src={footerBg} alt="" />
      </div>

      <animated.div
        ref={bgEl1Ref}
        style={bgEl1Spring}
        className="bg-element w-[30.1875rem] h-[30.1875rem] -top-1 -left-[15rem] sm:w-[253px] sm:h-[253px] sm:top-[144px] sm:-left-[117px]"
      >
        <div className="bg-element w-full h-full bg-[#3829AE] blur-[50px] opacity-40 top-0 left-0 rounded-full" />
        <img
          src="/image/bgEl-5.png"
          className="absolute h-[15rem] w-[15rem] top-[42.65%] left-[34.576%] sm:w-[125px] sm:h-[125px] sm:top-[108px] sm:left-[87px]"
          alt=""
        />
      </animated.div>

      <animated.div
        ref={bgEl2Ref}
        style={bgEl2Spring}
        className="bg-element w-[30.1875rem] h-[30.1875rem] -bottom-[19.625rem] -right-[12.25rem] sm:w-[253px] sm:h-[253px] sm:-bottom-[206px] sm:-right-[235px]"
      >
        <div className="bg-element w-full h-full bg-[#3829AE] blur-[50px] opacity-40 top-0 left-0 rounded-full" />
        <img
          src="/image/bgEl-1.png"
          className="absolute h-[15rem] w-[15rem] top-[30.228%] left-[21.739%] sm:hidden"
          alt=""
        />
        <div className="w-2.5 h-2.5 bg-[#D06650] absolute top-[12.629%] left-[48.447%] rotate-45 sm:top-[117px] sm:-left-[3px] sm:scale-50" />
        <div className="w-2.5 h-2.5 bg-[#D06650] absolute top-[24.224%] left-[55.28%] sm:top-[144px] sm:left-[6px] sm:scale-50" />
      </animated.div>
    </div>
  );
};
