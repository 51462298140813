import { isShowModel } from '@/utils/util';
import { About } from './About';
import { Advantage } from './Advantage';
import { Banner } from './Banner';
import { Introduce } from './Introduce';
import { Partners } from './Partners';
import { UserScenario } from './UserScenario';

export default function HomePage() {
  return (
    <div>
      <div className="mb-[17.8125rem]  mt-[6.0625rem] sm:mb-20 ms:mt-[50px] ">
        <Banner />
      </div>
      <div className="mb-20">
        <UserScenario />
      </div>
      <div className="mb-20">
        <Introduce />
      </div>
      <div className="mb-20">
        <Advantage />
      </div>
      {isShowModel() && (
        <>
          <div className="mb-20">
            <About />
          </div>
          <div>
            <Partners />
          </div>
        </>
      )}
    </div>
  );
}
