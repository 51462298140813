import {
  Partial,
  PickAnimated,
  SpringRef,
  UseSpringProps,
  useSpringRef,
  useSprings,
} from '@react-spring/web';
import { merge } from 'lodash-es';

const config = {
  mass: 2,
  tension: 220,
};

export function useEntranceSprings(
  number: number,
  formatConfig?: (i: number) => Partial<UseSpringProps> | undefined,
  deps: any[] = [],
): [PickAnimated<UseSpringProps>, SpringRef] {
  const apiRef = useSpringRef();

  const [springs] = useSprings(
    number,
    (i) => {
      const result: UseSpringProps = {
        ref: apiRef,
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
        delay: i * 100,
        config,
      };

      const customizeResult = formatConfig?.(i) ?? {
        from: { y: 100 },
        to: { y: 0 },
      };
      return merge({}, result, customizeResult);
    },
    deps,
  );

  return [springs, apiRef];
}
