import classNames from 'classnames';
import { memo } from 'react';
import styles from './StrokeCard.module.less';

interface IStrokeCardProps {
  strokeType?: 'horizontal' | 'full';
  contentClassName?: string;
}

const StrokeCard: FC<IStrokeCardProps> = ({
  className,
  children,
  strokeType = 'horizontal',
  contentClassName,
}) => {
  const wrapClassNames = classNames(styles.strokeCardWrap, className, {
    [styles.horizontal]: strokeType === 'horizontal',
    [styles.full]: strokeType === 'full',
  });

  const contentWrapClassNames = classNames(
    'h-full',
    {
      'bg-cardBg': !contentClassName?.split(' ').some((s) => s.includes('bg-')),
    },
    contentClassName,
    styles.contentWrap,
  );

  return (
    <div className={wrapClassNames}>
      <div className={contentWrapClassNames}>{children}</div>
    </div>
  );
};

export default memo(StrokeCard);
