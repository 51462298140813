import {StrokeCard} from '@/components/Card';
import {useInViewAnimated} from '@/hooks/useInViewAnimated';
import {animated, useInView, useSpring} from '@react-spring/web';
import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import Icon from "@/components/Icon";

const ScenarioData = [
    {
        key: '1',
        title: 'Text Generation',
        desc: 'Effortlessly craft original content, from blog posts to social media updates.',
    },
    {
        key: '2',
        title: 'Virtual Assistants',
        desc: 'Develop intelligent assistants capable of understanding user requests and engaging in meaningful dialogue.',
    },
    {
        key: '3',
        title: 'Text and Image Search',
        desc: 'Synthesize information from vast text and image datasets to provide valuable recommendations.',
    },
    {
        key: '4',
        title: 'Text Summarization',
        desc: 'Efficiently extract key information from lengthy documents, articles, and reports.',
    },
    {
        key: '5',
        title: 'Image and Video Generation',
        desc: 'Quickly produce visually stunning images and animations for various applications.',
    },
];

const UseCases: FC = ({className}) => {
    return (
        <div className={classNames(className, 'w-[194px] h-[194px] relative')}>
            <img src="/image/use-cases.png" alt=""/>
            <div className="text-center absolute w-full bottom-[18%] left-0">
                <div className="text-xl leading-none">Use Cases</div>
                <div className="mt-2 text-base text-desc">Beyond Boundaries</div>
            </div>
        </div>
    );
};

const DataRow: FC<{
    data: (typeof ScenarioData)[number];
    index: number;
    animate: boolean;
    onSuccess: (i: number) => void;
    onHover: (i: number) => void;
}> = ({data, index, animate, onSuccess, onHover}) => {
    const wrapRef = useRef<HTMLDivElement>(null);
    const descRef = useRef<HTMLDivElement>(null);
    const [elHeight, setElHeight] = useState<number>();
    const [descHeight, setDescHeight] = useState<number>();
    const [progress, setProgress] = useState(true);

    const [props] = useSpring(() => {
        if (typeof elHeight === 'undefined' || !elHeight || !descHeight) {
            return {};
        }
        return {
            wrapSize: animate ? elHeight : elHeight - descHeight,
            descSize: animate ? descHeight : 0,
        };
    }, [elHeight, descHeight, animate]);

    const [springs] = useSpring(() => {
        if (typeof elHeight === 'undefined' || !elHeight || !descHeight) {
            return {width: '0%'};
        }
        const isAnimate = animate && progress;
        return {
            delay: 300,
            width: isAnimate ? '100%' : '0%',
            immediate: !isAnimate,
            config: {duration: 5000},
            onRest: () => {
                if (!progress) {
                    onSuccess(index);
                } else {
                    setProgress(false);
                }
            },
        };
    }, [elHeight, descHeight, animate, progress]);

    useEffect(() => {
        setElHeight(wrapRef.current?.clientHeight ?? 0);
        setDescHeight(descRef.current?.clientHeight ?? 0);
    }, []);
    useEffect(() => {
        if (!progress && animate) {
            setProgress(true);
        }
    }, [animate]);
    return (
        <div
            key={data.key}
            className={classNames('relative pb-5 sm:pb-3', {
                'mb-5': index < ScenarioData.length - 1,
            })}
            onMouseEnter={() => {
                onHover(index);
            }}
        >
            <animated.div
                className={'overflow-hidden flex flex-col justify-end'}
                ref={wrapRef}
                style={{height: props.wrapSize}}
            >
                <h2 className="text-xl mb-3 sm:text-base">{data.title}</h2>
                <animated.p
                    style={{height: props.descSize}}
                    className="text-base text-desc sm:text-sm"
                    ref={descRef}
                >
                    <div>{data.desc}</div>
                </animated.p>
            </animated.div>

            <div className="absolute w-full h-[2px] bg-f9 bg-opacity-70 bottom-0 left-0 rounded-full"/>
            <animated.div
                style={springs}
                className="absolute h-[2px] bg-[#ED6859] bottom-0 left-0 rounded-full"
            />
        </div>
    );
};

const Scenario: FC = () => {
    const [animateIndex, setAnimateIndex] = useState(0);
    const [inViewRef, inView] = useInView();
    const handleSuccess = (i: number) => {
        setAnimateIndex(i >= ScenarioData.length - 1 ? 0 : i + 1);
    };

    const handleHover = (i: number) => setAnimateIndex(i);
    return (
        <div ref={inViewRef}>
            {ScenarioData.map((item, index) => {
                return (
                    <DataRow
                        key={item.key}
                        data={item}
                        index={index}
                        animate={animateIndex === index && inView}
                        onSuccess={handleSuccess}
                        onHover={handleHover}
                    />
                );
            })}
        </div>
    );
};

export const UserScenario: FC = () => {
    const [titleRef, titleSpring] = useInViewAnimated();
    const [card1Ref, card1Spring] = useInViewAnimated();
    const [card2Ref, card2Spring] = useInViewAnimated();
    const [card3Ref, card3Spring] = useInViewAnimated();
    const [card4Ref, card4Spring] = useInViewAnimated();
    const [card5Ref, card5Spring] = useInViewAnimated();
    const [bgElTRef, bgElTSpring] = useInViewAnimated(() => {
        return {
            from: {x: -300, y: 300, scale: 0},
            to: {x: 0, y: 0, scale: 1},
        };
    });
    const [bgElBRef, bgElBSpring] = useInViewAnimated(
        () => {
            return {
                from: {x: 300, y: -300, scale: 0},
                to: {x: 0, y: 0, scale: 1},
            };
        },
        {rootMargin: '-45% 0px -45% 0px'},
    );

    return (
        <div className="relative content" id="PRODUCT">
            <div className="relative">
                <animated.h1
                    ref={titleRef}
                    style={titleSpring}
                    className="text-5xl text-center sm:text-[1.75rem] sm:leading-[1.3]"
                >
                    <span className="text-primary">Everything</span> you need to build
                    generative AI applications
                </animated.h1>
                <animated.div
                    ref={card1Ref}
                    style={card1Spring}
                    className="flex mt-10 gap-x-10 sm:block"
                >
                    <StrokeCard
                        className="flex-1 text-base sm:mb-5"
                        contentClassName="px-10 py-8 sm:p-5"
                    >
                        <div className="flex justify-between">
                            <div>
                                <h2 className="text-3xl leading-none sm:text-base">
                                    For Application Developers
                                </h2>
                                <p className="pt-3 pb-5 sm:text-sm">
                                    Unleash Creativity with One API
                                </p>
                                <p className="text-desc sm:text-sm">
                                    One API, limitless possibilities. Customize and train
                                    multimodal models effortlessly.
                                </p>
                            </div>
                            <div className="pt-2 shrink-0">
                                <Icon icon="local:UserScenario1"/>
                            </div>
                        </div>
                    </StrokeCard>

                    <StrokeCard
                        className="flex-1 text-base"
                        contentClassName="px-10 py-8 sm:p-5"
                    >
                        <div className="flex justify-between">
                            <div className="flex-1 shrink-0">
                                <h2 className="text-3xl leading-none sm:text-base">
                                    For Model Developers
                                </h2>
                                <p className="pt-3 pb-5 sm:text-sm">
                                    Monetize Your Creations, Drive Innovation
                                </p>
                                <p className="text-desc sm:text-sm">
                                    Let your model earn for you. Share your trained model and earn
                                    on every API call.
                                </p>
                            </div>
                            <div className="pt-2 shrink-0">
                                <Icon icon="local:UserScenario2"/>
                            </div>
                        </div>
                    </StrokeCard>
                </animated.div>

                <div className="flex mt-10 gap-x-10 sm:hidden">
                    <animated.div
                        ref={card2Ref}
                        style={card2Spring}
                        className="w-[17.8125rem]"
                    >
                        <StrokeCard
                            className="h-full"
                            strokeType="full"
                            contentClassName="flex items-center justify-center"
                        >
                            <UseCases/>
                        </StrokeCard>
                    </animated.div>

                    <animated.div
                        ref={card3Ref}
                        style={card3Spring}
                        className="flex-1 shrink-0"
                    >
                        <Scenario/>
                    </animated.div>
                </div>

                <div className="hidden mt-5 sm:block">
                    <StrokeCard contentClassName="p-5" strokeType="full">
                        <animated.div ref={card4Ref} style={card4Spring} className="mb-5">
                            <UseCases className="mx-auto"/>
                        </animated.div>
                        <animated.div ref={card5Ref} style={card5Spring}>
                            <Scenario/>
                        </animated.div>
                    </StrokeCard>
                </div>
            </div>
            <animated.div
                ref={bgElTRef}
                style={bgElTSpring}
                className="bg-element w-[15rem] h-[15rem] -top-40 right-20 sm:w-[192px] sm:h-[192px] sm:right-0 sm:-left-[80px] sm:-top-[45px]"
            >
                <img
                    src="/image/bgEl-1.png"
                    className="w-full h-full sm:hidden"
                    alt=""
                />
                <img
                    src="/image/bgEl-5.png"
                    className="hidden w-full h-full sm:block"
                    alt=""
                />
            </animated.div>
            <animated.div
                ref={bgElBRef}
                style={bgElBSpring}
                className="bg-element w-[15rem] h-[15rem] -bottom-[7.5rem] -left-[7.5rem]"
            >
                <div
                    className="absolute w-full h-full bg-[#601676] blur-[50px] opacity-40 top-0 left-0 rounded-full"></div>
                <Icon
                    className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-[9.375rem] h-[9.375rem]"
                    icon="local:bgEl-4"
                />
                <div className="absolute w-2.5 h-2.5 bg-[#5D36F6] rounded-full top-[40%] left-10 scale-75"/>
                <div className="absolute w-2.5 h-2.5 bg-[#5D36F6] rounded-full top-[70%] left-8"/>
                <div className="absolute w-2.5 h-2.5 bg-[#5D36F6] rounded-full top-[50%] right-8"/>
            </animated.div>
        </div>
    );
};
