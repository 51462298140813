import {
  Partial,
  PickAnimated,
  SpringRef,
  useInView,
  UseSpringProps,
  useSpringRef,
} from '@react-spring/web';
import { merge } from 'lodash-es';

export function useInViewAnimated(
  formatConfig?: () => Partial<UseSpringProps> | undefined,
  ags?: Recordable,
): [React.RefObject<any>, PickAnimated<UseSpringProps>, SpringRef] {
  const apiRef = useSpringRef();
  const [inViewRef, springs] = useInView(
    () => {
      const result: UseSpringProps = {
        ref: apiRef,
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
      };
      const customizeResult = formatConfig?.() ?? {
        from: { y: 100 },
        to: { y: 0 },
      };
      return merge({}, result, customizeResult);
    },
    { once: true, ...(ags ?? {}) },
  );

  return [inViewRef, springs, apiRef];
}
