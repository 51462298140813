import classNames from 'classnames';
import {useState} from 'react';
import styles from './coockie.module.less';

const key = 'Accept';

const is = !!localStorage.getItem(key);

export const CookiesElement: FC = () => {
    const [open, setOpen] = useState(is);

    if (!open) {
        return null;
    }
    return (
        <div
            className={classNames(
                styles.cookieWrap,
                'fixed bottom-0 left-0 w-full bg-[#3125A0] pt-[32px] pb-[42px] z-50',
            )}
        >
            <div className="text-base text-center sm:text-sm sm:px-4">
                This website uses cookies to ensure you get the best experience on our
                website.
            </div>
            <div className="mt-5 text-center">
                <button
                    type="button"
                    className="rounded-full w-[157px] h-[41px] text-[#000D3D] sm:w-[136px]"
                    onClick={() => {
                        localStorage.setItem(key, 'true');
                        setOpen(false);
                    }}
                >
                    Accept
                </button>
            </div>
        </div>
    );
};
