import { StrokeCard } from '@/components/Card';
import { useInViewAnimated } from '@/hooks/useInViewAnimated';
import { animated } from '@react-spring/web';

const dataSource = [
  {
    key: '1',
    label: 'API Layer',
    titles: ['Off-chain', 'On-chain'],
    values: [['API', 'Oracle on Solana']],
  },
  {
    key: '2',
    label: 'Model Layer',
    titles: ['Foundation Models', 'Customized Models'],
    values: [
      ['LLaMa 2', 'Claude', 'Language Model', 'Image Model'],
      ['Stable Diffusion', 'more...', 'Audio Model', 'Video Model'],
    ],
  },
  {
    key: '3',
    label: 'Training Layer',
    titles: [
      'Data Management',
      'Model Tuning',
      'Context Enhancement',
      'ModelOps',
    ],
    values: [
      ['Data Annotation', 'Post-pretraining', 'Vector Database', 'Statistics'],
      ['Data Cleaning', 'SFT', 'Prompt Template', 'Evaluation Tools'],
      ['Dataset Management', 'RLHF', 'Prompt Engineering', 'Debugger'],
    ],
  },

  {
    key: '4',
    label: 'DePINs Layer',
    titles: ['GPU DePINs', 'Cloud DePINs'],
    values: [
      ['io.net', 'CLORE.AI', 'Akash Network', 'IoTeX'],
      ['Render Network', 'more...', 'Arweave', 'more…'],
    ],
  },
];

const DataRow: FC<{
  row: (typeof dataSource)[number];
}> = ({ row }) => {
  const [ref, spring] = useInViewAnimated();
  return (
    <animated.div
      ref={ref}
      style={spring}
      className="flex gap-x-3 rounded-lg sm:gap-x-1 sm:rounded-sm"
    >
      <div className="inline-flex-xy-center w-20 p-3 bg-cardBg rounded-inherit shrink-0 sm:w-[42px] sm:p-1">
        {row.label}
      </div>
      <div className="flex flex-col gap-y-3 p-3 flex-1 bg-cardBg rounded-inherit shrink-0 sm:gap-y-1 sm:p-1">
        <div className="flex gap-x-3 sm:gap-x-1">
          {row.titles.map((title, i) => (
            <div
              key={`${row.key}-${i}`}
              className="inline-flex-xy-center flex w-full text-primary"
            >
              {title}
            </div>
          ))}
        </div>

        {row.values.map((value, i) => (
          <div
            key={`${row.key}-${i}`}
            className="flex gap-x-3 rounded-inherit sm:gap-x-1"
          >
            {value.map((v, j) => (
              <StrokeCard
                key={`${row.key}-${i}-${j}`}
                className="flex-1 !rounded-inherit"
                contentClassName="flex-xy-center px-3 py-1 min-h-10 bg-[#3C28DB] sm:px-1 sm:min-h-[25px]"
              >
                {v}
              </StrokeCard>
            ))}
          </div>
        ))}
      </div>
    </animated.div>
  );
};

export const Introduce: FC = () => {
  const [titleRef, titleSpring] = useInViewAnimated();
  const [bgEl1Ref, bgEl1Spring] = useInViewAnimated(() => {
    return { to: [{ x: '-100%' }, { x: '0%', opacity: 1 }] };
  });

  const [bgEl2Ref, bgEl2Spring] = useInViewAnimated(
    () => {
      return { to: [{ x: '100%' }, { x: '0%', opacity: 1 }] };
    },
    { rootMargin: '-45% 0px -45% 0px' },
  );
  return (
    <div className="relative">
      <div className="text-base text-center content">
        <animated.h1
          ref={titleRef}
          style={titleSpring}
          className="text-5xl mb-10 sm:text-[1.75rem] sm:leading-[1.3]"
        >
          Decentralization enables{' '}
          <span className="text-primary">Security, Privacy and Safety</span>
        </animated.h1>
        <div className="flex flex-col gap-y-3 text-base leading-none sm:text-[0.5rem] sm:gap-y-1">
          {dataSource.map((row) => {
            return <DataRow key={row.key} row={row} />;
          })}
        </div>
      </div>

      <animated.div
        ref={bgEl1Ref}
        style={bgEl1Spring}
        className="bg-element w-[24.375rem] h-[24.375rem] -bottom-10 -left-[14rem] sm:hidden"
      >
        <div className="absolute w-full h-full top-0 left-0 bg-[#3829AE] opacity-40 blur-[3.125rem] rounded-full" />
        <img
          src="/image/bgEl-5.png"
          className="absolute left-[35%] top-0 w-[14rem] h-[14rem]"
          alt=""
        />
        <div className="w-2.5 h-2.5 bg-[#5D36F6] absolute bottom-[30%] right-[20%] rotate-45" />
        <div className="w-2.5 h-2.5 bg-[#5D36F6] absolute bottom-[10%] right-[30%]" />
      </animated.div>

      <animated.div
        ref={bgEl2Ref}
        style={bgEl2Spring}
        className="bg-element w-[24.375rem] h-[24.375rem] -bottom-10 -right-[14rem]"
      >
        <div className="absolute w-full h-full top-0 left-0 bg-[#601676] opacity-40 blur-[3.125rem] rounded-full" />
        <img
          src="/image/bgEl-1.png"
          className="absolute right-[35%] top-[80%] w-[14rem] h-[14rem]"
          alt=""
        />
        <div className="w-2.5 h-2.5 bg-[#D06650] absolute bottom-[60%] left-[20%] rotate-45 sm:hidden" />
        <div className="w-2.5 h-2.5 bg-[#D06650] absolute bottom-[40%] left-[30%] sm:hidden" />
      </animated.div>
    </div>
  );
};
