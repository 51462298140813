import classNames from 'classnames';
import React, { memo } from 'react';
import styles from './style.module.less';

interface IButtonProps {
  buttonClassName?: string;
  spin?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  link?: string;
  linkName?: string;
}

const Button: FC<IButtonProps> = ({
  className,
  children,
  spin,
  buttonClassName,
  onClick,
  link,
}) => {
  if (!children) return null;

  const wrapClassName = classNames(
    'p-2 rounded-full inline-block relative',
    className,
    styles.buttonWrap,
    {
      [styles.spin]: spin,
    },
  );

  const buttonClassNames = classNames('px-8 py-2', buttonClassName, {
    ['bg-white text-textMain']: spin,
    ['border-2 border-solid border-white/40 text-white']: !spin,
  });

  const button = (
    <button type="button" className={buttonClassNames} onClick={onClick}>
      {children}
    </button>
  );

  return (
    <div className={classNames(wrapClassName)}>
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          {button}
        </a>
      ) : (
        button
      )}
    </div>
  );
};

export default memo(Button);
