const iconsMap = new Map();


iconsMap.set('local:about1', '/svg/about1.svg');
iconsMap.set('local:about2', '/svg/about2.svg');
iconsMap.set('local:Advantage1', '/svg/Advantage1.svg');
iconsMap.set('local:Advantage2', '/svg/Advantage2.svg');
iconsMap.set('local:Advantage3', '/svg/Advantage3.svg');
iconsMap.set('local:Advantage4', '/svg/Advantage4.svg');
iconsMap.set('local:Advantage5', '/svg/Advantage5.svg');
iconsMap.set('local:Advantage6', '/svg/Advantage6.svg');
iconsMap.set('local:bgEl-1', '/svg/bgEl-1.svg');
iconsMap.set('local:bgEl-2', '/svg/bgEl-2.svg');
iconsMap.set('local:bgEl-3', '/svg/bgEl-3.svg');
iconsMap.set('local:bgEl-4', '/svg/bgEl-4.svg');
iconsMap.set('local:bgEl-5', '/svg/bgEl-5.svg');
iconsMap.set('local:header-logo', '/svg/header-logo.svg');
iconsMap.set('local:menu-icon-h5', '/svg/menu-icon-h5.svg');
iconsMap.set('local:UserScenario1', '/svg/UserScenario1.svg');
iconsMap.set('local:UserScenario2', '/svg/UserScenario2.svg');
iconsMap.set('local:UserScenario3', '/svg/UserScenario3.svg');
iconsMap.set('ri:arrow-right-up-line', '/image/arrow-lu.png');


const CompatibleIcon: FC<{ icon: string, [key: string]: any }> = ({icon, ...props}) => {
    if (iconsMap.has(icon)) {
        return <img src={iconsMap.get(icon)} {...props} />
    }
    return null;

}


export default CompatibleIcon;
