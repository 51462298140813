import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Layout from "@/layouts";
import HomeView from "@/pages/home";

let router = createBrowserRouter([
    {
        path: "/",
        loader: () => ({message: "Hello Data Router!"}),
        Component: Layout,
        children: [
            {path: "", Component: HomeView}
        ]
    },
]);

export default function App() {
    return <RouterProvider router={router} fallbackElement={<p>Loading...</p>}/>;
}
