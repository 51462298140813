import { Drawer, motionProps } from '@/components/Drawer';
import { isShowModel } from '@/utils/util';
import { useDebounceFn, useScroll } from 'ahooks';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import {useLocation,useNavigate} from "react-router-dom";

import Icon from "@/components/Icon";





export const LayoutHeader: FC = () => {
  const position = useScroll(document, ({ top }) => {
    return top < 50 || (position?.top ?? 0) < 20;
  });

  const [open, setOpen] = useState(false);

  const [activeLink, setActiveLink] = useState('HOME');

  const location = useLocation();

  const navigate = useNavigate();

  const goGoAnchorPoint = useCallback((name: string) => {
    setOpen(false);
    setActiveLink(name);
    navigate(`/#${name}`);
  }, []);

  const menuData = [
    {
      key: 'HOME',
      onClick: () => {
        goGoAnchorPoint('HOME');
      },
    },
    {
      key: 'PRODUCT',
      onClick: () => {
        goGoAnchorPoint('PRODUCT');
      },
    },
    ...(isShowModel()
      ? [
          {
            key: 'ABOUT $MODEL',
            anchorPoint: 'ABOUT',
            onClick: () => {
              goGoAnchorPoint('ABOUT');
            },
          },
        ]
      : []),
    {
      key: 'DOCS',
      anchorPoint: null,
      after: (
        <Icon
          className="w-[14px] h-[14px] sm:w-5 sm:h-5"
          icon="ri:arrow-right-up-line"
        />
      ),
      link: 'https://demodel.medium.com/',
      onClick: () => {
        setOpen(false);
      },
    },
  ];

  const getTargetY = useCallback((element: Element) => {
    const elementTop = element.getBoundingClientRect().top;
    const startingY = window.scrollY || document.documentElement.scrollTop;
    return elementTop + startingY - 150;
  }, []);

  const { run: handleScroll } = useDebounceFn(
    () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const sections = ['ABOUT', 'PRODUCT', 'HOME'];

      for (const section of sections) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const targetY = getTargetY(sectionElement);

          if (Math.ceil(targetY) <= scrollPosition) {
            setActiveLink(section);
            break;
          }
        }
      }
    },
    { wait: 100 },
  );

  useEffect(() => {
    const { hash } = location;
    if (!hash) return;
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      let targetY;
      if (hash === '#HOME') {
        targetY = 0;
      } else {
        targetY = getTargetY(targetElement);
      }

      window.scrollTo({ top: targetY, behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={classNames('fixed w-full top-0 left-0 z-50', {
        'bg-[#000D3D]': (position?.top ?? 0) > 20,
      })}
    >
      <div className="flex items-center justify-between content h-header sm:h-minHeader">
        <div className="w-[221px] shrink-0">
          <Icon icon="local:header-logo" width="100%" />
        </div>
        <div className="flex items-center text-base gap-x-5 sm:hidden">
          {menuData.map((item) => {
            return (
              <a
                href={item.link}
                key={item.key}
                target="_blank"
                rel="noreferrer"
                onClick={item.onClick}
                className={classNames(
                  'flex cursor-pointer items-center gap-x-5 px-10 rounded-full [&.active]:bg-white [&.active]:bg-opacity-20 h-[49px] hover:bg-white hover:bg-opacity-20 transition-colors',
                  {
                    active: activeLink === (item.anchorPoint || item.key),
                  },
                )}
              >
                <span>{item.key}</span>
                {item.after && item.after}
              </a>
            );
          })}
        </div>
        <div className="hidden sm:block" onClick={() => setOpen(true)}>
          <Icon icon="local:menu-icon-h5" />
        </div>
      </div>
      <Drawer
        open={open}
        width="100%"
        placement="right"
        onClose={() => setOpen(false)}
        {...motionProps}
      >
        <div
          className="h-full"
          style={{
            backgroundColor: '#000D3D',
            backgroundImage:
              'linear-gradient(90deg, rgba(95,56,251,0.4) 0%, #5F38FB 100%)',
          }}
        >
          <div className="flex justify-between items-center h-minHeader px-[16px] mb-[80px]">
            <div className="w-[221px] shrink-0">
              <Icon icon="local:header-logo" width="100%" />
            </div>
            <div onClick={() => setOpen(false)}>
              <Icon icon="local:menu-icon-h5" />
            </div>
          </div>

          {menuData.map((item, index) => {
            return (
              <a
                href={item.link}
                key={item.key}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  'px-[16px] text-[1.75rem] block [&.active]:text-primary',
                  {
                    'mb-[80px]': index < menuData.length - 1,
                    active: activeLink === (item.anchorPoint || item.key),
                  },
                )}
                onClick={item.onClick}
              >
                <div className="flex items-center gap-x-3">
                  <div>{item.key}</div>
                  {item.after && item.after}
                </div>
              </a>
            );
          })}
        </div>
      </Drawer>
    </header>
  );
};
