import { StrokeCard } from '@/components/Card';
import { useEntranceSprings } from '@/hooks/useEntranceSprings';
import { useInViewAnimated } from '@/hooks/useInViewAnimated';
import { animated, useChain, useInView } from '@react-spring/web';
import { set } from 'lodash-es';
import Icon from "@/components/Icon";

const dataSource = [
  {
    key: '1',
    icon: <Icon icon="local:Advantage1" width="100%" height="100%" />,
    title: 'Decentralized Power',
    description: (
      <span>
        Run your applications on DePINs,
        <br className="sm:hidden" /> eliminating{' '}
        <br className="hidden sm:block" /> the need for centralized{' '}
        <br className="sm:hidden" /> service providers.
      </span>
    ),
  },
  {
    key: '2',
    icon: <Icon icon="local:Advantage2" width="100%" height="100%" />,
    title: 'Limitless Creativity',
    description: (
      <span>
        No restrictions, biases, or censorship. <br className="sm:hidden" /> Let
        AI <br className="hidden sm:block" /> flourish without limitations.
      </span>
    ),
  },
  {
    key: '3',
    icon: <Icon icon="local:Advantage3" width="100%" height="100%" />,
    title: 'Rewards for Innovation',
    description: (
      <span>
        Share your customized training models <br className="sm:hidden" /> and
        get <br className="hidden sm:block" /> rewarded with $MODEL tokens{' '}
        <br className="sm:hidden" /> for every API{' '}
        <br className="hidden sm:block" /> call.
      </span>
    ),
  },
  {
    key: '4',
    icon: <Icon icon="local:Advantage4" width="100%" height="100%" />,
    title: 'Diverse Models',
    description: (
      <span>
        Access high-performance models from <br className="sm:hidden" /> leading
        AI companies like Anthropic, <br className="sm:hidden" /> Meta, and
        Stability AI, <br className="hidden sm:block" /> alongside{' '}
        <br className="sm:hidden" /> customized models from the community.
      </span>
    ),
  },
  {
    key: '5',
    icon: <Icon icon="local:Advantage5" width="100%" height="100%" />,
    title: 'Simplified Development',
    description: (
      <span>
        One API seamlessly calling various <br className="sm:hidden" /> models,
        <br className="hidden sm:block" />
        eliminating the complexities of <br className="sm:hidden" /> deployment.
      </span>
    ),
  },
  {
    key: '6',
    icon: <Icon icon="local:Advantage6" width="100%" height="100%" />,
    title: 'Multimodal Capabilities',
    description: (
      <span>
        Beyond language models, DeModel <br className="sm:hidden" /> supports{' '}
        <br className="hidden sm:block" />
        image, audio, and video <br className="sm:hidden" /> models for diverse{' '}
        <br className="hidden sm:block" />
        applications.
      </span>
    ),
  },
];

export const Advantage: FC = () => {
  const [titleRef, titleSpring] = useInViewAnimated();

  const [inViewRef, inView] = useInView({ once: true });

  const [elementSprings, elementApi] = useEntranceSprings(
    6,
    () => {
      let result;
      if (!inView) {
        result = {};
        set(result, 'to.opacity', 0);
      }
      return result;
    },
    [inView],
  );

  useChain([elementApi]);

  const [bgEl1Ref, bgEl1Spring] = useInViewAnimated(
    () => {
      return {
        to: [
          { x: '-100%', y: -100 },
          { x: '0%', opacity: 1, y: 0 },
        ],
      };
    },
    { rootMargin: '-45% 0px -45% 0px' },
  );

  const [bgEl2Ref, bgEl2Spring] = useInViewAnimated(() => {
    return { to: [{ x: '-100%' }, { x: '0%', opacity: 1 }] };
  });
  return (
    <div className="relative">
      <div className="relative content">
        <div>
          <animated.h1
            ref={titleRef}
            style={titleSpring}
            className="text-5xl mb-10 text-center sm:text-[1.75rem] sm:leading-[1.3]"
          >
            Why Choose <span className="text-primary">DeModel?</span>
          </animated.h1>
          <div className="grid grid-cols-3 gap-10 sm:block" ref={inViewRef}>
            {dataSource.map((item, index) => {
              return (
                <animated.div
                  key={item.key}
                  style={elementSprings[index]}
                  className="sm:mb-5"
                >
                  <StrokeCard strokeType="full">
                    <div className="h-[16.75rem] py-10 sm:h-[181px] sm:py-4">
                      <div className="pl-10 sm:pl-5">
                        <div className="flex items-center justify-center w-10 h-10 sm:w-7 sm:h-7">
                          {item.icon}
                        </div>

                        <h2 className="text-[1.75rem] py-5 leading-none sm:text-base sm:pb-3">
                          {item.title}
                        </h2>
                        <p className="text-base text-desc sm:text-sm">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </StrokeCard>
                </animated.div>
              );
            })}
          </div>
        </div>

        <div className="bg-element w-[24.375rem] h-[24.375rem] bg-[#3829AE] blur-[50px] opacity-40 top-0 -left-[10rem] rounded-full" />

        <div className="bg-element hidden sm:block w-[286px] h-[286px] bg-[#3829AE] blur-[50px] opacity-40 top-[294px] -right-[111px] rounded-full" />
        <div className="bg-element hidden sm:block w-[24.375rem] h-[24.375rem] bg-[#601676] blur-[50px] opacity-40 top-[485px] -left-[138px] rounded-full" />

        <animated.div
          ref={bgEl1Ref}
          style={bgEl1Spring}
          className="bg-element w-[24.375rem] h-[24.375rem] -bottom-[12.6875rem] -right-10 sm:-right-[156px] sm:-bottom-[171px]"
        >
          <div className="bg-element w-full h-full bg-[#3829AE] blur-[50px] opacity-40 top-0 left-0 rounded-full" />
          <Icon
            icon="local:bgEl-3"
            width="100%"
            height="100%"
            className="absolute top-[60%] left-[18.974%] w-[8.9375rem] h-[8.9375rem] sm:left-[74px] sm:bottom-[14px]"
          />
          <div className="w-2.5 h-2.5 bg-[#3B2BB6] absolute top-[63.59%] left-[64.615%] rotate-45 sm:top-[228px] sm:left-[212px]" />
          <div className="w-2.5 h-2.5 bg-[#3B2BB6] absolute top-[71.538%] left-[63.333%] sm:top-[260px] sm:left-[207px]" />
        </animated.div>
      </div>
      <animated.div
        ref={bgEl2Ref}
        style={bgEl2Spring}
        className="bg-element w-[11.625rem] h-[11.625rem] md:top-[50%] -left-[5.5rem] sm:w-[84px] sm:h-[84px] sm:-left-[26px] sm:-bottom-[47px]"
      >
        <Icon icon="local:bgEl-4" width="100%" height="100%" />
      </animated.div>
    </div>
  );
};
