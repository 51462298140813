import { Outlet } from 'react-router-dom';
import { CookiesElement } from './Cookies';
import { LayoutFooter } from './Footer';
import { LayoutHeader } from './Header';

export default function Layout() {
  return (
    <>
      <LayoutHeader />
      <div className="overflow-hidden" id="HOME">
        <div className="h-header sm:h-minHeader"></div>
        <div>
          <Outlet />
        </div>
        <div className="mt-28 sm:mt-[6.875rem]">
          <LayoutFooter />
        </div>
      </div>
      <CookiesElement />
    </>
  );
}
