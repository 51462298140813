import { Button } from '@/components/Button';
import { useEntranceSprings } from '@/hooks/useEntranceSprings';
import { isShowModel } from '@/utils/util';
import { animated, useChain } from '@react-spring/web';
import { set } from 'lodash-es';
import Icon from "@/components/Icon";

export const Banner: FC = () => {
  const [elementSprings, elementApi] = useEntranceSprings(5);

  const [bgElSprings, bgElApi] = useEntranceSprings(4, (i) => {
    const result = {};
    if (i === 0) {
      set(result, 'from.x', '-100%');
      set(result, 'to.x', '0%');
    } else if (i === 1) {
      set(result, 'from.scale', 0);
      set(result, 'to.scale', 1);
    } else {
      set(result, 'from.x', '100%');
      set(result, 'to.x', '0%');
    }

    return result;
  });

  useChain([elementApi, bgElApi], [0, 0.5]);

  const showModel = isShowModel();

  const buttonClass = 'w-[230px] h-[60px]';
  return (
    <div className="relative">
      <div className="relative content">
        <animated.h1
          className="text-[4.5rem] leading-[1.2] sm:text-[2.5rem] sm:text-center"
          style={{ ...elementSprings[0] }}
        >
          Revolutionizing Generative AI with{' '}
          <span className="text-primary">Decentralization</span>
        </animated.h1>

        <animated.p
          className="mt-10 text-2xl mb-14 sm:text-base sm:text-center"
          style={{ ...elementSprings[1] }}
        >
          Your Gateway to Limitless Generative AI Innovation, Powered by the
          Decentralized <br className="hidden sm:block" /> Magic of{' '}
          <span className="text-primary">DePIN</span>.
        </animated.p>
        <div className="flex text-lg gap-x-11 sm:flex-col sm:gap-y-[20px]">
          {showModel && (
            <animated.div
              className="sm:text-center"
              style={{ ...elementSprings[2] }}
            >
              <Button spin buttonClassName={buttonClass}>
                Buy $MODEL
              </Button>
            </animated.div>
          )}

          <animated.div
            className="sm:text-center"
            style={{ ...elementSprings[showModel ? 3 : 2] }}
          >
            <Button
              buttonClassName={buttonClass}
              link="https://forms.gle/ZtEi12GycWs39jKn6"
            >
              Join Waitlist
            </Button>
          </animated.div>

          <animated.div
            className="sm:text-center"
            style={{ ...elementSprings[showModel ? 4 : 3] }}
          >
            <Button
              buttonClassName={buttonClass}
              link="https://twitter.com/DeModelNetwork"
            >
              X
            </Button>
          </animated.div>
        </div>

        <animated.div
          className="bg-element w-[40.625rem] h-[40.625rem] rounded-full opacity-40 blur-[50px] -top-20 -right-20"
          style={{
            background: 'linear-gradient(114deg, #5137E4 0%, #251E87 100%)',
          }}
        />

        <animated.div
          className="bg-element w-[10.8125rem] h-[10.8125rem] -bottom-16 right-32 sm:w-[77.67px] sm:h-[77.67px] sm:bottom-[170px] sm:right-0 sm:-left-[13px]"
          style={{ ...bgElSprings[1] }}
        >
          <Icon width="100%" height="100%" icon="local:bgEl-3" />
        </animated.div>
        <animated.div
          className="bg-element w-[15rem] h-[15rem] -top-20 -left-[9.375rem]"
          style={{ ...bgElSprings[0] }}
        >
          <img
            src="/image/bgEl-5.png"
            className="w-full h-full sm:hidden"
            alt=""
          />
          <img
            src="/image/bgEl-1.png"
            className="hidden sm:block rotate-[30deg] w-full h-full"
          />
        </animated.div>
      </div>

      <animated.div
        className="bg-element w-[33.0625rem] h-[33.0625rem] -top-[20.875rem] -right-[15.1875rem] rounded-full sm:w-[263px] sm:h-[263px] sm:top-[252px] sm:-right-[144px]"
        style={{ ...bgElSprings[2] }}
      >
        <div className="absolute w-full h-full bg-[#FA3D97] blur-[50px] opacity-40 top-0 left-0 rounded-full"></div>
        <Icon
          className="absolute left-[6.8125rem] top-10 w-[23.875rem] h-[23.875rem] sm:w-[190px] sm:h-[190px] sm:left-[49px] sm:top-[17px]"
          width="100%"
          height="100%"
          icon="local:bgEl-2"
        />
        <div className="absolute w-1.5 h-1.5 bg-[#D06650] left-[9.3125rem] top-[22.875rem] rotate-45" />
        <div className="absolute w-1.5 h-1.5 bg-[#D06650] left-[10.5625rem] top-[25rem]" />
      </animated.div>

      <animated.div
        style={{ ...bgElSprings[3] }}
        className="bg-element w-[30.625rem] h-[30.625rem] -top-5 -right-[18.375rem] sm:w-[244px] sm:h-[244px] sm:top-[398px] sm:-right-[176px]"
      >
        <Icon width="100%" height="100%" icon="local:bgEl-4" />
        <div className="absolute w-1.5 h-1.5 bg-[#D06650] top-[68.566%] -left-1.5 rotate-45" />
        <div className="absolute w-1.5 h-1.5 bg-[#D06650] top-[74.816%] left-1.5" />
      </animated.div>
    </div>
  );
};
